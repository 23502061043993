import VueRouter from 'vue-router'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { getOriginationNextRouteWithCurrentPath } from '@/flow/originationFlow'
import { sharedPagePaths } from '@/routes/sharedRoutes'
import { RouteOption } from '@/flow/flowUtility'
import { logger } from '@/utils/logger'

export enum Flows {
    origination = 'origination',
    mailerOrigination = 'mailerOrigination',
}

export let latestPath = 'initializationPath'

export const getNextRoute = (router: VueRouter, routeOption?: RouteOption): string => {
    const nextPath = getNextPath(router.currentRoute.path, routeOption)
    latestPath = nextPath
    return nextPath
}

export const getNextPath = (currPath: string, routeOption?: RouteOption): string => {
    const currentFlow = appSessionStorage.getItem(localStorageKey.currentFlow) as Flows | null

    if (currentFlow === Flows.origination) {
        const nextPath = getOriginationNextRouteWithCurrentPath(currPath, routeOption)
        return nextPath || sharedPagePaths.PAGE_NOT_FOUND
    }

    logger.info('flow not found, could not determine next page')
    return sharedPagePaths.PAGE_NOT_FOUND
}
