import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { httpClient } from '@/utils/http-client'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { currentContextForLogging } from '@/main'
import isEmpty from 'lodash/isEmpty'
import utilInspect from 'util-inspect'

export const LogLevel = {
    // mirrors the levels used on the backend
    debug: 'DEBUG',
    log: 'INFO',
    info: 'INFO',
    warn: 'WARN',
    error: 'FATAL',
    fatal: 'FATAL',
}

/**
 * We use this as the error that we send to Sentry when our code only sends an error message,
 * but not a thrown Error (or Error subclass). For example:
 * logger.error('some message without an error')
 * As opposed to:
 * logger.error('some message with an error', error)
 *
 * Without this message wrapper, we can't actually group different Sentry messages because Sentry
 * automatically tries to group by stacktrace, which is not appropriate for error messages without
 * thrown errors.
 */
class SentryMessage extends Error {
    constructor(msg: string) {
        super(msg)
        this.name = 'SentryMessage'
        Object.setPrototypeOf(this, new.target.prototype)
    }
}

export const heraclesLogPath = '/logs'

const inspect = (msg: Object) => {
    return utilInspect(msg, { breakLength: Infinity })?.replace(/[\s]*[\n][\s]*/g, '; ')
}

// these are error messages we want to demote to 'warn' severity instead of 'exception' severity
const demotedErrorMessages = [
    /Network Error/gi, // A temporary network problem that is /usually/ the on client's side / mobile networks / etc
    /Unexpected token/gi, // This is caused when we deploy while someone is on the site and they get a 404 from the missing file
    /Loading.*chunk.*failed./gi, // This is also caused by a bad client-side cache and/or deploy
]

const Logger = class {
    isDevelopment = process.env.VUE_APP_CLIENT_SIDE_LOGS_ENABLED === 'yes'
    isNetworkLoggingEnabled = true

    setNetworkLogging = (isEnabled: boolean) => {
        console.log('Setting network logging to: ' + isEnabled)
        this.isNetworkLoggingEnabled = isEnabled
    }

    debug = (message: string, event?: PromiseRejectionEvent) => {
        if (this.isDevelopment) {
            console.debug(message, event)
        }
        this.trySendToBackend(message, event, LogLevel.debug)
    }

    log = (message: string, event?: PromiseRejectionEvent) => {
        if (this.isDevelopment) {
            console.log(message, event)
        }
        this.trySendToBackend(message, event, LogLevel.log)
    }

    info = (message: string, event?: PromiseRejectionEvent) => {
        if (this.isDevelopment) {
            console.info(message, event)
        }
        this.trySendToBackend(message, event, LogLevel.info)
    }

    warn = (message: string, event?: null | Event | PromiseRejectionEvent, error?: any) => {
        if (this.isDevelopment) {
            console.warn(message, event)
        }
        this.trySendToBackend(message, event, LogLevel.warn, error)
    }

    error = (message: string, event?: null | Event | PromiseRejectionEvent, error?: any) => {
        try {
            message = this.formatMessage(message)
            if (!message) {
                return // Don't send empty messages
            }

            // Redirect network errors to warn() so we don't trigger a high-severity Sentry and
            // page the on-call engineer
            if (message?.includes('Network Error')) {
                this.warn(message, event, error)
                return
            }

            if (this.isDevelopment) {
                console.error(message, event, error)
            }
        } catch (e) {
            message = `NOTE: Failed to pre-process logger.error message due to error ${e}! ` + message
        }

        this.trySendToBackend(message, event, LogLevel.error, error, true /* includeIdsAndContextInLog */)
    }

    trySendToBackend = async (message: string, event?: null | Event | PromiseRejectionEvent, level?: string, error?: any, includeIdsAndContextInLog = false) => {
        message = this.formatMessage(message)
        if (!message || !this.isNetworkLoggingEnabled) {
            return // Don't send empty messages
        }

        let location = 'not_set'
        try {
            const url = new URL(window.location.href)
            location = url.host + url.pathname

            if (event) {
                message += `\tEventJSON: ${JSON.stringify(event)}`
            }
        } catch (e) {
            message = `NOTE: Failed to append some items to message due to error ${e}! ` + message
        }

        try {
            const logMessage = includeIdsAndContextInLog ? `Identities: ${this.getUserIdentities()}\tMESSAGE BEGIN:\t${message}\t${this.getContextDataInfo()}` : message
            const postBody = {
                message: logMessage,
                level,
            }

            await httpClient.post(heraclesLogPath, postBody)

            if (level === LogLevel.error || level === LogLevel.warn) {
                // Note that LogLevel.Error maps to Sentry.Severity.Fatal purposefully because
                // our frontend's highest LogLevel is Error, while Sentry's is Fatal
                const severity: Sentry.Severity = level === LogLevel.error ? Sentry.Severity.Fatal : Sentry.Severity.Error
                this.logMessageToSentry(message, location, null /* tag */, severity, error)
            } else {
                let severity
                if (level === LogLevel.info) {
                    severity = Sentry.Severity.Info
                } else if (level === LogLevel.log) {
                    severity = Sentry.Severity.Log
                } else {
                    severity = Sentry.Severity.Debug
                }
                this.addLogBreadcrumbToSentry(severity, message)
            }
        } catch (error) {
            if (this.isDevelopment) {
                console.error(`Could not log message to backend due to error: ${inspect(error as Object)}\nThe message was ${message}`)
            }
            this.logMessageToSentry(`Could not log message to backend\nThe message was ${message}`, location, 'isPossibleNetworkIssue', Sentry.Severity.Error, error)
        }
    }

    /**
     * Breadcrumbs are used to create a trail of events prior to an issue.
     * https://docs.sentry.io/platforms/javascript/enriching-events/breadcrumbs/
     */
    addLogBreadcrumbToSentry = (severity: Sentry.Severity, message: string) => {
        Sentry.addBreadcrumb({
            // https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
            type: 'info',
            level: severity,
            message,
        })
    }

    logMessageToSentry(message: string, location: string, tag: string | null, severity: Sentry.Severity, error?: any) {
        try {
            const noSessionId = !appSessionStorage.getItem(localStorageKey.sessionId)
            const fullMessage = `${noSessionId ? 'No Session Id! ' : ''}${tag || ''}@${location}\n\n${message}`

            const captureContext: any = {
                level: severity ?? Sentry.Severity.Fatal,
                extra: {
                    message: fullMessage,
                    logRocketUrl: appSessionStorage.getItem(localStorageKey.logRocketUrl) || 'not_set',
                    identities: this.getUserIdentities(),
                    contextData: this.getContextDataInfo(),
                    // @ts-ignore navigator connection hasn't been standardized yet.
                    networkQuality: `type: ${navigator?.connection?.effectiveType}, downlink: ${navigator?.connection?.downlink}, online: ${navigator?.onLine}`,
                },
                tags: {
                    noSessionId: noSessionId ? 'true' : undefined,
                },
            }

            if (tag) {
                captureContext['tags'][tag] = 'true'
            }

            const errorToSend = error ?? new SentryMessage(message)
            Sentry.captureException(errorToSend, captureContext)
        } catch (e) {
            Sentry.captureException(new Error(`Failed to capture exception due to error: ${e}\nException was ${message}`))
        }
    }

    formatMessage = (message: string) => {
        if (!message) {
            return ''
        }
        if (typeof message === 'object') {
            if (isEmpty(message)) {
                return ''
            }
            return inspect(message)
        }

        return message.trim()
    }

    getContextDataInfo = () => {
        let dataInfo = 'COMPONENT DATA:\n'
        try {
            for (const [contextName, context] of Object.entries(currentContextForLogging)) {
                const contextData = (context as any)._data
                const data = Object.assign({}, contextData)
                const info = inspect(data)
                if (Object.keys(data).length !== 0 && !info.includes('[Getter/Setter]')) {
                    // don't include components with no data and don't include built-in components like observers that have getters/setters
                    dataInfo += `Data of ${contextName}: ${inspect(data)}\n\n`
                }
            }

            dataInfo += `SESSION STORAGE:\n${JSON.stringify(appSessionStorage.getAll())}`
        } catch (e) {
            dataInfo += `\nFailed to log additional error data due to error: ${e}`
        }
        return dataInfo
    }

    getUserIdentities = () => {
        const applicantId = appSessionStorage.getItem(localStorageKey.applicantId)
        const loanApplicationId = appSessionStorage.getItem(localStorageKey.loanApplicationId)
        const sessionId = appSessionStorage.getItem(localStorageKey.sessionId)

        const identityArray = []
        if (sessionId) {
            identityArray.push(`S: ${sessionId}`)
        }
        if (applicantId) {
            identityArray.push(`A: ${applicantId}`)
        }
        if (loanApplicationId) {
            identityArray.push(`L: ${loanApplicationId}`)
        }

        let identities = 'None'
        if (identityArray.length > 0) {
            identities = '[' + identityArray.join(' | ') + ']'
        }
        return identities
    }

    constructor() {
        const isScraper = /google|fbid|facebook|fbav|fb_/gi.test(navigator.userAgent)
        const isLocalDev = process.env.VUE_APP_NODE_ENV === 'development'
        // Strip http/https from URLs
        const allowUrls = [process.env.VUE_APP_AVEN_URL].map((x) => x.replace(/^https?:\/\//gi, ''))
        if (!isLocalDev && !isScraper) {
            Sentry.init({
                dsn: process.env.VUE_APP_SENTRY_ID,
                environment: process.env.VUE_APP_NODE_ENV,
                release: process.env.VUE_APP_SENTRY_RELEASE,
                allowUrls: !this.isDevelopment ? allowUrls : undefined,
                maxValueLength: 1024 * 8,
                attachStacktrace: true,
                defaultIntegrations: false,
                integrations: [
                    new Sentry.Integrations.FunctionToString(),
                    new Sentry.Integrations.InboundFilters(),
                    new Sentry.Integrations.Breadcrumbs(),
                    new Sentry.Integrations.LinkedErrors(),
                    new Sentry.Integrations.UserAgent(),
                ],
                initialScope: {
                    tags: {
                        // add any global static tags here
                        avenProject: 'aven-crypto',
                    },
                },
            })
        } else {
            console.info('[INFO] Refusing to log development and/or Googlebot to sentry')
        }

        // eslint-disable-next-line no-unused-vars
        Vue.config.errorHandler = (err, vm, info) => {
            // Can't serialize 'vm', which is a recursively defined Vue object (also no point)
            // 'info' isn't that useful, only has a short string description
            // err is the actual stack trace
            const msg = `vue error: ${err}\t${err.stack}`
            if (demotedErrorMessages.some((regex) => regex.test(String(err.message)))) {
                this.warn(msg, null /* event */, err)
            } else {
                this.error(msg, null /* event */, err)
            }
        }

        window.onunhandledrejection = (event: PromiseRejectionEvent) => {
            // See: https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58
            if (/Loading.*chunk.*failed./i.test(event.reason.message || '')) {
                this.info('Reloading page to fix stale chunk error')
                const url = new URL(window.location.href)
                const location = url.host + url.pathname
                this.logMessageToSentry(
                    `window.onunhandledrejection error: Reloading page to fix stale chunk error: ${inspect(event.reason)}`,
                    location,
                    'isPossibleNetworkIssue',
                    Sentry.Severity.Warning
                )
                return window.location.reload(true)
            }

            const reason = event.reason
            const msg = `window.onunhandledrejection error: ${inspect(reason)}`
            if (demotedErrorMessages.some((regex) => regex.test(String(reason)))) {
                this.warn(msg, event, reason instanceof Error ? reason : undefined)
            } else {
                this.error(msg, event, reason instanceof Error ? reason : undefined)
            }
        }

        window.onerror = (message, source, lineno, colno, error) => {
            const msg = `window.onerror error: ${inspect({ message, source, lineno, colno, error })}`
            if (demotedErrorMessages.some((regex) => regex.test(String(message)))) {
                this.warn(msg, message as Event, error)
            } else {
                this.error(msg, message as Event, error)
            }
        }
    }
}

const logger = new Logger()

export { logger, inspect }
